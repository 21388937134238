import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import Cookies from 'universal-cookie';

import CartContext from '../contexts/CartContext';

import Selector from '../mobileComponents/Selector';
import Input from '../mobileComponents/Input';
import InfoDrawer from '../mobileComponents/InfoDrawer';
import OrderItem from '../mobileComponents/OrderItem';
import Popup from '../mobileComponents/Popup';
import StandardOption from '../mobileStyles/StandardOption';
import StandardInput from '../mobileStyles/StandardInput';

import { photoMap3 } from '../../functions/PhotoMap';
import { getSelectorIDs, getSelectorNames, getSelectorPrice } from '../../functions/SelectorHandler';

// import FullHeart from '../../assets/FullHeart.svg';
// import EmptyHeart from '../../assets/EmptyHeart.svg';
// import OneFourthHeart from '../../assets/OneFourthHeart.svg';
// import HalfHeart from '../../assets/HalfHeart.svg';
// import ThreeFourthHeart from '../../assets/ThreeFourthHeart.svg';
import MinusSign from '../../assets/MinusSign.svg';
import PlusSign from '../../assets/PlusSign.svg';
import Share from '../../assets/Share.svg'
import MobileItemBackground from '../../assets/MobileItemBackground.svg';
import PhotoInput from '../mobileComponents/PhotoInput';

const cookies = new Cookies(null, { path: '/' });
// cookies.remove('order', { path: '/' });

function createShareURL(flavor, size, toppings, frosting, inscription, inscriptionStyle, colors, notes, quantity) {
    var url = window.location.href.split('/').slice(0, 6).join('/');
    console.log(url);

    const options = [];

    if (flavor)
        url += '/?flavor=' + encodeURIComponent(flavor);
    url += '/?size=' + size;
    url += '/?toppings=' + toppings;
    url += '/?frosting=' + frosting;
    url += '/?inscription=' + inscription;
    url += '/?inscription_style=' + inscriptionStyle;
    url += '/?colors=' + colors;
    url += '/?notes=' + notes;
    url += '/?quantity=' + quantity;

    return url;
}

function Item(props) {
    const { handleAddToCart } = useContext(CartContext);
    let { categoryName, itemName } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const toCart = () => {
        navigate('/checkout');
    }

    const [item, setItem] = useState({
        name: '',
        second_name: '',
        url: '',
        category_name: '',
        category_url: '',
        size_options: [],
        flavor_options: [],
        frosting_options: [],
        topping_options: [],
        price_tag: '',
        base_price: -1,
        rating: -1,
        allows_colors: false,
        allows_inscription: false,
        allows_second_flavor: false,
        allows_reference_photos: false
    });

    const [flavor, setFlavor] = useState([]);
    const [size, setSize] = useState([]);
    const [toppings, setToppings] = useState([]);
    const [frosting, setFrosting] = useState([]);
    const [inscription, setInscription] = useState('');
    const [inscriptionStyle, setInscriptionStyle] = useState(0);
    const [colors, setColors] = useState('');
    const [references, setReferences] = useState([]);
    const [notes, setNotes] = useState('');

    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(-1);

    const [confirm, setConfirm] = useState(false);
    const [confirmError, setConfirmError] = useState('');

    const [share, setShare] = useState(false);

    const uploader = (file, id) => {
        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            localStorage.setItem('image-'+id,reader.result);
            // setUrl(localStorage.getItem('image-'+id));
        });
        reader.readAsDataURL(file);
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/item?categoryName=' + categoryName + '&itemName=' + itemName).then(res => {
            return res.json();
        }).then(res => {
            if (res.name) setItem(res);
            // console.log('res: ' + JSON.stringify(res, null, 4));
            if (res.flavor_options.length === 1) {
                setFlavor(0);
            }
        });
    }, []);

    useEffect(() => {
        if (item.base_price >= 0) {
            console.log('item', item);
            console.log('size:', parseInt(searchParams.get('size')), item.size_options.findIndex(o => o.id === parseInt(searchParams.get('size'))));
            if (searchParams.get('size')) setSize(item.size_options.findIndex(o => o.id === parseInt(searchParams.get('size'))));
            if (item.allows_second_flavor) {
                if (searchParams.get('flavor')) setFlavor([item.flavor_options.find((flavor) => item.name.includes(flavor.name)), item.flavor_options.find(o => o.id === parseInt(searchParams.get('flavor')))]);
            } else {
                if (searchParams.get('flavor')) setFlavor(item.flavor_options.findIndex(o => o.id === parseInt(searchParams.get('flavor'))));
            }
            if (searchParams.get('toppings')) {
                const toppingParam = searchParams.get('toppings').split(',');
                setToppings(item.topping_options.filter(o => toppingParam.includes(o.id.toString())));
            }
            if (searchParams.get('frosting')) setFrosting(item.frosting_options.findIndex(o => o.id === parseInt(searchParams.get('frosting'))));
            if (searchParams.get('inscription')) setInscription(decodeURIComponent(searchParams.get('inscription')));
            if (searchParams.get('inscription_style')) setInscriptionStyle(inscriptionStyles.findIndex(o => o.id === parseInt(searchParams.get('inscription_style'))));
            if (searchParams.get('colors')) setColors(decodeURIComponent(searchParams.get('colors')));
            if (searchParams.get('notes')) setNotes(decodeURIComponent(searchParams.get('notes')));
        }
    }, [item]);

    useEffect(() => {
        var newPrice = parseInt(item.base_price);
        newPrice += parseInt(getSelectorPrice(item.flavor_options, flavor));
        newPrice += parseInt(getSelectorPrice(item.size_options, size));
        newPrice += parseInt(getSelectorPrice(item.topping_options, toppings));
        newPrice += parseInt(getSelectorPrice(item.frosting_options, frosting));
        setPrice(newPrice);
    }, [flavor, size, toppings, frosting]);

    useEffect(() => {
        if (item.flavor_options.length > 1 && item.size_options[size]?.name === 'Two Dozen Mini') {
            setFlavor(flavor.slice(0, 1));
        }
    }, [size]);

    useEffect(() => {
        if (confirmError === '') return;
        const timer = setTimeout(() => {
            setConfirmError('');
        }, 20000);
        return () => clearTimeout(timer);
    }, [confirmError]);

    const requireFlavor = item.category_name === 'Custom Cakes';
    const requireSize = item.size_options.length > 1;
    const requireFrosting = item.category_name === 'Custom Cakes';
    const requireColors = item.category_name === 'Custom Cakes';

    const handleClickAddToCart = () => {
        var errorMessage = 'Please select ';
        const missingFields = []
        if (requireSize && (typeof (size) !== typeof (0) || size < 0)) missingFields.push('a size');
        if (requireFlavor && (typeof (flavor) !== typeof (0) || flavor < 0)) missingFields.push('a flavor');
        if (requireFrosting && (typeof (frosting) !== typeof (0) || frosting < 0)) missingFields.push('a frosting option');
        if (requireColors && colors.length <= 0) missingFields.push('colors');
        if (missingFields.length > 0) {
            errorMessage += missingFields.join(', ') + '.';
            console.log('error: ' + errorMessage);
            setConfirmError(errorMessage);
        } else {
            setConfirm(true);
        }
    }

    const handleConfirm = () => {
        let orderItem = {
            item: item,
            states: {
                size: size,
                flavor: flavor,
                toppings: toppings,
                frosting: frosting
            },
            receipt: {
                item_id: item.id,
                name: item.name + ' ' + item.second_name,
                size: getSelectorNames(item.size_options, size),
                flavor: getSelectorNames(item.flavor_options, flavor),
                toppings: getSelectorNames(item.topping_options, toppings),
                frosting: getSelectorNames(item.frosting_options, frosting),
                inscription: inscription,
                inscription_style: (inscriptionStyle === 0 ? 'Cursive' : 'Print'),
                colors: colors,
                notes: notes,
                price: price,
                quantity: quantity,
                total_price: price * quantity
            }
        }
        handleAddToCart(orderItem);
        toCart();
    }

    const handleClickShare = () => {
        var errorMessage = 'Please select ';
        const missingFields = []
        if (requireSize && (typeof (size) !== typeof (0) || size < 0)) missingFields.push('a size');
        if (requireFlavor && (typeof (flavor) !== typeof (0) || flavor < 0)) missingFields.push('a flavor');
        if (requireFrosting && (typeof (frosting) !== typeof (0) || frosting < 0)) missingFields.push('a frosting option');
        if (requireColors && colors.length <= 0) missingFields.push('colors');
        if (missingFields.length > 0) {
            errorMessage += missingFields.join(', ') + ' to share.';
            console.log('error: ' + errorMessage);
            setConfirmError(errorMessage);
        } else {
            setShare(true);
        }
    }

    const handleCopyToClipboard = () => {
        var url = window.location.href.split('/').slice(0, 6).join('/') + '/?';

        const options = [];

        if (getSelectorIDs(item.size_options, size).length > 0) options.push('size=' + getSelectorIDs(item.size_options, size));
        if (getSelectorIDs(item.flavor_options, flavor).length > 0) {
            if (item.allows_second_flavor) {
                options.push('flavor=' + flavor.find((flavor) => !item.name.includes(flavor.name)).id);
            } else if (requireFlavor) {
                options.push('flavor=' + getSelectorIDs(item.flavor_options, flavor));
            }
        }
        if (getSelectorIDs(item.topping_options, toppings).length > 0) options.push('toppings=' + getSelectorIDs(item.topping_options, toppings));
        if (getSelectorIDs(item.frosting_options, frosting).length > 0) options.push('frosting=' + getSelectorIDs(item.frosting_options, frosting));
        if (inscription?.length > 0) options.push('inscription=' + encodeURIComponent(inscription));
        if (inscription?.length > 0 && getSelectorIDs(inscriptionStyles, inscriptionStyle).length > 0) options.push('inscription_style=' + getSelectorIDs(inscriptionStyles, inscriptionStyle));
        if (colors?.length > 0) options.push('colors=' + encodeURIComponent(colors));
        if (notes?.length > 0) options.push('notes=' + encodeURIComponent(notes));

        url += options.join('&');

        navigator.clipboard.writeText(url);
        setShare(false);
    }

    // const reviewFrames = [];
    // let i = 0;
    // // console.log('Reviews: ' + JSON.stringify(reviews));
    // for (const review of reviews) {
    //     reviewFrames.push(<Frame key={i} className='item-review column' gap='8rem'>
    //         <Frame className='item-review-header column' gap='8rem'>
    //             <Frame className='item-review-line2'>
    //                 <p className='font-21 primary frame flex-100 bold'><span className='primary'>{review.headline}</span></p>
    //                 <Frame className='item-review-rating center' gap='6rem'>
    //                     <img className='mobile-item-review-star' src={review.rating >= 1 ? FullHeart : EmptyHeart}></img>
    //                     <img className='mobile-item-review-star' src={review.rating >= 2 ? FullHeart : EmptyHeart}></img>
    //                     <img className='mobile-item-review-star' src={review.rating >= 3 ? FullHeart : EmptyHeart}></img>
    //                     <img className='mobile-item-review-star' src={review.rating >= 4 ? FullHeart : EmptyHeart}></img>
    //                     <img className='mobile-item-review-star' src={review.rating >= 5 ? FullHeart : EmptyHeart}></img>
    //                 </Frame>
    //             </Frame>
    //         </Frame>
    //         <p className='item-review-text frame stretch column'>
    //             <span className='float-left bold'>"{review.review}"</span>
    //             <span className='float-right end'>- {review.firstName} {review.lastName}</span>
    //         </p>
    //         <p className='font-18 float-right end frame stretch'>{review.date}</p>
    //     </Frame>);
    //     i++;
    // }

    const inscriptionStyles = [{ 'id': 1, 'name': 'Cursive' }, { 'id': 2, 'name': 'Print' }];

    return (
        <>
            <div className='frame column justify-between stretch true-white' style={{ gap: '40rem', padding: '42rem 0' }}>
                <div className='frame center relative' style={{ padding: '32rem 0rem 0rem 0rem', background: 'linear-gradient(180deg, #FEA0AF 0.5%, rgba(255, 255, 255, 0.00) 22%)' }}>
                    <Element name='top' />
                    <img style={{ width: '100vw', height: '527rem', objectFit: 'cover' }} src={MobileItemBackground}></img>
                    <img style={{ position: 'absolute', width: '75vw', left: '50%', transform: 'translate(-50%, 24%)', objectFit: 'contain' }} src={photoMap3.get(item.url)}></img>
                </div>
                <div className='frame column stretch flex-100' style={{ gap: '24rem' }}>
                    {typeof (flavor) === typeof (0) ?
                        <div className='frame column' style={{ padding: '0 12rem' }}>
                            <div className='frame column stretch'>
                                <p className='font-20 bold light-grey'>{item.second_name}</p>
                                <div className='frame row stretch justify-between'>
                                    <p className='font-24 bold'>{item.name}</p>
                                    <p className='font-24 bold'>{item.price_tag}</p>
                                </div>
                            </div>
                            {/* <Rating></Rating> */}
                            {flavor >= 0 && <p className='font-20 light-grey'>{item.flavor_options[flavor].description}</p>}
                        </div> :
                        <div className='frame column'>
                            <div className='frame column stretch' style={{ padding: '0 12rem' }}>
                                <p className='font-20 bold light-grey'>{item.second_name}</p>
                                <div className='frame row stretch justify-between'>
                                    <p className='font-24 bold'>{item.name}</p>
                                    <p className='font-24 bold'>{item.price_tag}</p>
                                </div>
                            </div>
                            {/* <Rating></Rating> */}
                            {item.flavor_options.length > 0 &&
                                <div className='frame column' style={{ gap: '16rem', padding: '0 12rem' }}>
                                    <p className='font-20 light-grey'>{item.flavor_options.find((flavor) => flavor.price === 0).description}</p>
                                    {flavor.length > 1 &&
                                        <>
                                            <div className='row-divider-1'></div>
                                            <p className='font-20 light-grey'>{flavor[1].description}</p>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    }
                    <div className='row-divider-1'></div>
                    {item.size_options.length > 1 &&
                        <>
                            <Selector
                                title={<p className='font-20 semi-bold'>Select a size<span className='primary'>*</span></p>}
                                info={getSelectorPrice(item.size_options, size) > 0 && <p className='font-20 primary'>+${getSelectorPrice(item.size_options, size)}</p>}
                                options={item.size_options}
                                state={size}
                                setState={setSize}
                                optionStyles={StandardOption()}
                                selectorStyle={{ gap: '12rem', padding: '0 12rem' }} />
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {(item.flavor_options.length > 1 && item.size_options[size]?.name !== 'Two Dozen Mini') &&
                        <>
                            <Selector
                                title={!item.allows_second_flavor ?
                                    <p className='font-20 semi-bold'>Select a flavor<span className='primary'>*</span></p> :
                                    <p className='font-20 semi-bold'>Select a second flavor</p>}
                                info={getSelectorPrice(item.flavor_options, flavor) > 0 && <p className='font-20 primary'>+${getSelectorPrice(item.flavor_options, flavor)}</p>}
                                options={item.flavor_options}
                                state={flavor}
                                setState={setFlavor}
                                multiSelect={item.allows_second_flavor ? 2 : false}
                                preSelected={item.allows_second_flavor ? [item.flavor_options.find((flavor) => item.name.includes(flavor.name))] : null}
                                optionStyles={StandardOption()}
                                selectorStyle={{ gap: '12rem', padding: '0 12rem' }}
                                wrap />
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {item.topping_options.length > 1 &&
                        <>
                            <Selector
                                title={<p className='font-20 semi-bold'>Add toppings</p>}
                                info={getSelectorPrice(item.topping_options, toppings) > 0 && <p className='font-20 primary'>+${getSelectorPrice(item.topping_options, toppings)}</p>}
                                options={item.topping_options}
                                state={toppings}
                                setState={setToppings}
                                multiSelect={-1}
                                optionStyles={StandardOption()}
                                selectorStyle={{ gap: '12rem', padding: '0 12rem' }}
                                wrap />
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {item.frosting_options.length > 1 &&
                        <>
                            <Selector
                                title={<p className='font-20 semi-bold'>Select frosting<span className='primary'>*</span></p>}
                                info={getSelectorPrice(item.frosting_options, frosting) > 0 && <p className='font-20 primary'>+${getSelectorPrice(item.frosting_options, frosting)}</p>}
                                options={item.frosting_options}
                                state={frosting}
                                setState={setFrosting}
                                optionStyles={StandardOption()}
                                selectorStyle={{ gap: '12rem', padding: '0 12rem' }}
                                wrap />
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {item.allows_inscription &&
                        <>
                            <div style={{ padding: '0 8rem' }}>
                                <Input
                                    title={<p className='font-20 semi-bold'>Cake inscription</p>}
                                    placeholder='Write a message!'
                                    state={inscription}
                                    setState={setInscription}
                                    inputStyle={StandardInput()}
                                    maxWords={6}
                                />
                            </div>
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {inscription.length > 0 &&
                        <>
                            <Selector
                                title={<p className='font-20 semi-bold'>Inscription style<span className='primary'>*</span></p>}
                                options={inscriptionStyles}
                                state={inscriptionStyle}
                                setState={setInscriptionStyle}
                                optionStyles={StandardOption()}
                                selectorStyle={{ gap: '12rem', padding: '0 12rem' }} />
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {item.allows_colors &&
                        <>
                            <div style={{ padding: '0 8rem' }}>
                                <Input
                                    title={<p className='font-20 semi-bold'>Select colors<span className='primary'>*</span> <span className='light-grey'>(Primary colors, pastels, white, black. NO bright neons)</span></p>}
                                    placeholder='Frosting, inscription...'
                                    state={colors}
                                    setState={setColors}
                                    inputStyle={StandardInput()}
                                />
                            </div>
                            <div className='row-divider-1'></div>
                        </>
                    }
                    {/* {item.allows_reference_photos &&
                        <>
                            <div className='frame column' style={{ padding: '0 8rem', gap: '24rem' }}>
                                <p className='font-20 semi-bold'>Reference photos</p>
                                <div className='reference-grid' style={{ width: '100%', gap: '4rem', justifyItems: 'center' }}>
                                    <PhotoInput
                                        id={0}
                                        state={references[0]}
                                        setState={(new_reference) => {
                                            const temp = [...references];
                                            temp[0] = new_reference;
                                            setReferences(temp);
                                        }}
                                        inputStyle={StandardInput()}
                                    />
                                    <PhotoInput
                                        id={1}
                                        state={references[1]}
                                        setState={(new_reference) => {
                                            const temp = [...references];
                                            temp[1] = new_reference;
                                            setReferences(temp);
                                        }}
                                        inputStyle={StandardInput()}
                                    />
                                    <PhotoInput
                                        id={2}
                                        state={references[2]}
                                        setState={(new_reference) => {
                                            const temp = [...references];
                                            temp[2] = new_reference;
                                            setReferences(temp);
                                        }}
                                        inputStyle={StandardInput()}
                                    />
                                    <PhotoInput
                                        id={3}
                                        state={references[3]}
                                        setState={(new_reference) => {
                                            const temp = [...references];
                                            temp[3] = new_reference;
                                            setReferences(temp);
                                        }}
                                        inputStyle={StandardInput()}
                                    />
                                </div>
                            </div>
                            <div className='row-divider-1'></div>
                        </>
                    } */}
                    <div style={{ padding: '0 8rem' }}>
                        <Input
                            title={<p className='font-20 semi-bold'>Notes</p>}
                            placeholder='Is there anything else we need to know?'
                            state={notes}
                            setState={setNotes}
                            inputStyle={StandardInput()}
                        />
                    </div>
                    {/* <div className='row-divider-1 invisible'></div> */}
                    <div className='frame column stretch' style={{ padding: '0 8rem' }}>
                        <div className='frame row center stretch' style={{ gap: '8rem' }}>
                            <div className='frame center' style={{ gap: '16rem', padding: '8rem 16rem', borderRadius: '10rem', border: '2.5rem solid var(--lightest-grey)' }}>
                                <img className={'minus-sign' + (quantity <= 1 ? '' : ' hover-pointer')} style={{ width: '28rem', height: '28rem' }}
                                    src={MinusSign} onClick={quantity > 1 ? () => setQuantity(quantity - 1) : () => { }}></img>
                                <p className='font-20 bold'>{quantity}</p>
                                <img className={'plus-sign' + (quantity >= 10 ? '' : ' hover-pointer')} style={{ width: '28rem', height: '28rem' }}
                                    src={PlusSign} onClick={quantity <= 9 ? () => setQuantity(quantity + 1) : () => { }}></img>
                            </div>
                            <div className='frame center primary stretch flex-100 hover-pointer' style={{ gap: '16rem', padding: '8rem 16rem', borderRadius: '10rem', border: '2.5rem solid var(--primary)' }}
                                onClick={handleClickAddToCart}>
                                <p className='bold font-20'>Add to Cart</p>
                            </div>
                            <div className='frame center hover-pointer' style={{ gap: '16rem', padding: '4rem 8rem', borderRadius: '10rem', border: '2.5rem solid var(--lightest-grey)' }} onClick={handleClickShare}>
                                <img style={{ width: '24rem', height: '24rem' }} src={Share}></img>
                            </div>
                        </div>
                        {confirmError.length > 0 &&
                            <p className='font-20 primary'>{confirmError}</p>
                        }
                    </div>
                    <div className='row-divider-1'></div>
                    <InfoDrawer title={<p className='font-20 semi-bold'>Ingredients</p>}>
                        {typeof (flavor) === typeof (0) ?
                            <p className='font-16 stretch'>{item.flavor_options[flavor].ingredients.length > 0 ? item.flavor_options[flavor].ingredients : 'Select a flavor to see ingredients'}</p> :
                            <div className='frame column'>
                                {/* <Rating></Rating> */}
                                {item.flavor_options.length > 0 &&
                                    <div className='frame row' style={{ gap: '16rem' }}>
                                        <div className='frame column'>
                                            <p className='font-16 bold light-grey'>{item.name}</p>
                                            <p className='font-16 light-grey'>{item.flavor_options.find((flavor) => flavor.price === 0).ingredients}</p>
                                        </div>
                                        {flavor.length > 1 &&
                                            <>
                                                <div className='column-divider-1'></div>
                                                <div className='frame column'>
                                                    <p className='font-16 bold light-grey'>{flavor[1].name}</p>
                                                    <p className='font-16 light-grey'>{flavor[1].ingredients}</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </InfoDrawer>
                    {item.category_name.includes('Cake') &&
                        <>
                            <div className='row-divider-1'></div>
                            <InfoDrawer title={<p className='font-20 semi-bold'>How to preserve cakes</p>}>
                                <p className='stretch font-16 light-grey'>
                                    <span className='black'>To ensure cake safety and preservation, we recommend...</span>
                                    <br />
                                    <ul>
                                        <li>Placing the cake on the <span className='primary'>car floor with A/C</span> during transit</li>
                                        <br />
                                        <li>Keeping your cake <span className='primary'>refrigerated</span> if your cake has whipped, cream frosting, fresh fruit, or mascarpone. Take your cake out of the fridge <span className='primary'>right before serving</span>.</li>
                                        <br />
                                        <li>Storing cakes with buttercream icing and no perishables as listed above outside the fridge at <span className='primary'>room temperature</span> to preserve moisture and softness.</li>
                                    </ul>
                                    Chefska’s Cravings assumes no liability for damages or accidents occurring after pickup. <span className='dark-grey'>Additional charges may be incurred upon return and modifications.</span></p>
                            </InfoDrawer>
                        </>
                    }
                    <div className='row-divider-1'></div>
                    <InfoDrawer title={<p className='font-20 semi-bold'>Terms & Conditions</p>}>
                        <p className='stretch font-16 light-grey'>
                            <span className='bold'>All orders must be placed at least 5 days in advance for pickup only. If you need it before this time, please contact us <span className='primary'>chefskascravings@gmail.com</span>.</span>
                            <br /><br />
                            No refunds for orders orders canceled 24 hours before pickup.
                            <br /><br />
                            50% refund for orders canceled more than 24 hours before pickup.
                            <br /><br />
                            Orders can be rescheduled 48 hours before pickup time.
                            <br /><br />
                            Cake artist can make any modification that she considers necessary on the decoration.
                            <br /><br />
                            Seasonal ingredients are subject to change, and we reserve the right to make reasonable substitutions while ensuring the overall quality and flavor profile of our products.
                            <br /><br />
                            For darker colors, bold colors are not available for whipped cream.
                            <br /><br />
                            Customers with allergies or dietary restrictions should review our ingredient lists and inform us of any special requirements before placing an order. We will do our best to accommodate your needs. We do not offer gluten-free at the moment.
                            <br /><br />
                            Certain cakes may require items for decoration and stability. Natural flowers and any ornamental items on cakes are for decoration only and should not be consumed.
                            <br /><br />
                            Portions estimated per cake will depend on the size of the slices when cutting the cake.
                            <br /><br />
                            Chefska’s Cravings assumes no liability for damages or accidents occurring after pickup. Additional charges may be incurred upon return and modifications.
                            <br /><br />
                            <span className='bold'>By placing the order you are accepting all terms and conditions.</span>
                        </p>
                    </InfoDrawer>
                    <div className='row-divider-1'></div>
                </div>
            </div >
            {confirm &&
                <Popup handleGoBack={() => setConfirm(false)}>
                    <OrderItem
                        item={item}
                        size={size}
                        flavor={flavor}
                        toppings={toppings}
                        frosting={frosting}
                        inscription={inscription}
                        inscriptionStyles={inscriptionStyles}
                        inscriptionStyle={inscriptionStyle}
                        colors={colors}
                        notes={notes}
                        price={price}
                        type='vert'
                    ></OrderItem>
                    <div className='frame center' style={{ gap: '16rem' }}>
                        <div className='frame primary inverted hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                            onClick={() => setConfirm(false)}>
                            <p className='bold font-20'>Edit</p>
                        </div>
                        <div className='frame primary hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                            onClick={handleConfirm}>
                            <p className='bold font-20'>Confirm</p>
                        </div>
                    </div>
                </Popup>
            }
            {
                share &&
                <>
                    <div className='confirm-background' onClick={() => setShare(false)}></div>
                    <div className='confirm-container column center' style={{ gap: '24rem', borderRadius: '20rem' }}>
                        <OrderItem
                            item={item}
                            size={size}
                            flavor={flavor}
                            toppings={toppings}
                            frosting={frosting}
                            inscription={inscription}
                            inscriptionStyles={inscriptionStyles}
                            inscriptionStyle={inscriptionStyle}
                            colors={colors}
                            notes={notes}
                            price={price}
                            type='vert'
                        ></OrderItem>
                        <div className='frame center' style={{ gap: '16rem' }}>
                            <div className='frame primary inverted hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={() => setShare(false)}>
                                <p className='bold font-20'>Edit</p>
                            </div>
                            <div className='frame primary hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={handleCopyToClipboard}>
                                <p className='bold font-20 nowrap'>Copy to Clipboard</p>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Item;